#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  /* background: #eee; */
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  /* color: #000; */
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
  /* background: #000; */
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  /* padding: 40px 60px; */
}


.button-container {
    position: relative;
    z-index: 10; /* Ensure it's above the image */
  }

  .swiper-button-next,
.swiper-button-prev {
  z-index: 30 !important;
}

.swiper-pagination {
  z-index: 30 !important;
}

.mySwiper {
  height: 100%;
  width: 100%;
}